[data-tooltip] {
  position: relative;
  cursor: default;
}

[data-tooltip]::after {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  content: attr(data-tooltip);
  left: 0;
  top: calc(100% + 10px);
  border-radius: 3px;
  box-shadow: 0 0 5px 2px rgba(100, 100, 100, 0.6);
  background-color: white;
  z-index: 10;
  padding: 8px;
  max-width: 60rem;
  transform: translateY(-20px);
  transition: all 150ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

[data-tooltip]:hover::after {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 300ms;
}

.country_section {
  background: lightblue;
  height: 400px;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: url(https://i.imgur.com/X2CV6KI.png) no-repeat center center;
}

.PageNotFound {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .PageNotFoundText {
        font-weight: 900;
        font-size: 32px;
    }
}
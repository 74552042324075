.portal-map-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  border: 2px solid #102f55;

  .text-info {
    position: absolute;
    top: 0;
    margin: 0.25rem;
  }
}

.title_section {
  background: lightblue;
  height: 200px;
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #e9fbf4 0%, #c2ecfe 100%);
}

.QueryResultListWrapper {
  margin-top: 0.5em;
}

[data-tooltip] {
  position: relative;
  cursor: default;
}

[data-tooltip]::after {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  content: attr(data-tooltip);
  left: 0;
  top: calc(100% + 10px);
  border-radius: 3px;
  box-shadow: 0 0 5px 2px rgba(100, 100, 100, 0.6);
  background-color: white;
  z-index: 10;
  padding: 8px;
  max-width: 60rem;
  transform: translateY(-20px);
  transition: all 150ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

[data-tooltip]:hover::after {
  opacity: 1;
  transform: translateY(0);
  transition-duration: 300ms;
}

.link {
  font-weight: 500;
  font-size: medium;
  text-decoration: underline;
}

.status {
  font-size: 14px;
}

.midFontSize {
  font-size: 14px;
}

.human-name {
  display: block;
  text-wrap: wrap;
}

.circle {
  font-weight: 500;
  font-size: medium;
  color: #003058;
  text-align: center;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyList {
  min-height: 400px;
  border-radius: 5px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #e9fbf4 0%, #c2ecfe 100%);
  img {
    max-height: 100px;
    opacity: 0.5;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    font-family: 'Ubuntu', sans-serif;
    line-height: 160%;
    text-align: justify;
    max-width: 400px;
    color: #444;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  a {
    background-color: #003058;
    color: white;
    align-items: center;
    border-radius: 0.5rem;
    border: none;
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    justify-content: center;
    line-height: 16px;
    max-width: auto;
    padding: 1.5rem 4rem;
    text-decoration: none;
    white-space: nowrap;
    width: 230px;
  }
}

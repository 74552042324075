@import url('https://fonts.googleapis.com/css2?family=Exo:wght@700&family=Ubuntu&display=swap');

html,
body {
  background: white;
  padding: 0;
  margin: 0;
  font-family: Ubuntu, sans-serif;
  scroll-behavior: smooth;
}

p,
b,
a {
  color: inherit;
  text-decoration: none;
  font-size: 0.875rem;
}

* {
  box-sizing: border-box;
}
